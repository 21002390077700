import React, { useState } from 'react'
import { cn, getLangIndex } from '../../lib/helpers'

import * as styles from './blog.module.css'
import BlogItem from './item'

const Blog = ({ blog, data, lang }) => {
  const currentLang = getLangIndex(blog, lang)

  const [categoryId, setCategory] = useState(blog[currentLang].categories[0])

  const items = (rawItems = []) =>
    rawItems
      .filter(item => item.categories.some(c => c === categoryId))
      .map((item, i) => (
        <BlogItem key={i} item={item} lang={lang} />
      ))

  const categories = (rawItems = []) =>
    rawItems.map((category, i) => (
      <div
        key={i}
        className={cn(styles.category, (categoryId.toLowerCase() === category.toLowerCase()) && styles.active)}
        onClick={() => setCategory(category)}
      >
        {category}
      </div>
    ))

  return (
    <div className={styles.root}>
      <h1>{blog[currentLang].pageTitle || 'Blog'}</h1>
      <h2>{blog[currentLang].pageSubtitle || 'Subtitle text here'}</h2>

      <div className={styles.categories}>
        {categories(blog[currentLang].categories)}
      </div>

      <div className={styles.cards}>
        {items(blog[currentLang].posts)}
      </div>
    </div>
  )
}

export default Blog
