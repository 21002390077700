// extracted by mini-css-extract-plugin
export var categories = "blog-module--categories--jyuCw";
export var category = "blog-module--category--dOjce";
export var active = "blog-module--active--JpIcJ";
export var cards = "blog-module--cards--jAMt+";
export var root = "blog-module--root--ALsrU";
export var buttons = "blog-module--buttons--OLEUz";
export var mode = "blog-module--mode--sMybe";
export var modeItem = "blog-module--modeItem--QaTDy";
export var card = "blog-module--card--0wVjl";
export var head = "blog-module--head--69qXr";
export var title = "blog-module--title--dgprA";
export var users = "blog-module--users--YP9mu";
export var benefits = "blog-module--benefits--syIbp";
export var benefit = "blog-module--benefit--DPfYt";
export var price = "blog-module--price--y5zU1";
export var priceUnit = "blog-module--priceUnit--y+KjJ";
export var freeFeatures = "blog-module--freeFeatures--nUc73";
export var menu = "blog-module--menu--3nrh-";
export var stats = "blog-module--stats--W6BS2";
export var green = "blog-module--green--WcD3t";
export var calculate = "blog-module--calculate--37TZN";
export var range = "blog-module--range--zx+uu";
export var usersTooltip = "blog-module--usersTooltip--DvJmm";
export var marks = "blog-module--marks--btium";
export var container = "blog-module--container--U4MzP";
export var nonProfit = "blog-module--nonProfit--XGIs4";
export var icon = "blog-module--icon--AsLsP";
export var wrapper = "blog-module--wrapper--LG92A";
export var nonProfitTail = "blog-module--nonProfitTail--A8D-x";