// extracted by mini-css-extract-plugin
export var blogItem = "item-module--blogItem--O6FDl";
export var image = "item-module--image--1Od8R";
export var category = "item-module--category--WC5Xk";
export var time = "item-module--time--Ye4VH";
export var link = "item-module--link--8rwxo";
export var root = "item-module--root--7Ni0Q";
export var buttons = "item-module--buttons--g+Ck5";
export var mode = "item-module--mode--ruQvX";
export var modeItem = "item-module--modeItem--jCpoa";
export var active = "item-module--active--npoNj";
export var cards = "item-module--cards--uWRSl";
export var card = "item-module--card--7swVd";
export var head = "item-module--head--PGk8n";
export var title = "item-module--title--osedU";
export var users = "item-module--users--P7uwL";
export var benefits = "item-module--benefits--SAlKg";
export var benefit = "item-module--benefit--lvUcF";
export var price = "item-module--price--iCBtb";
export var priceUnit = "item-module--priceUnit--SGMmK";
export var freeFeatures = "item-module--freeFeatures--42C2b";
export var menu = "item-module--menu--clqQ+";
export var stats = "item-module--stats--No4V-";
export var green = "item-module--green--Mm03C";
export var calculate = "item-module--calculate--x3NEC";
export var range = "item-module--range--yFpHw";
export var usersTooltip = "item-module--usersTooltip---LzYL";
export var marks = "item-module--marks--ABnpf";
export var container = "item-module--container--DTpID";
export var nonProfit = "item-module--nonProfit--nP7D4";
export var icon = "item-module--icon--vTxyX";
export var wrapper = "item-module--wrapper--d8IuM";
export var nonProfitTail = "item-module--nonProfitTail--NynE7";