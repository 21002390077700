import React from 'react'
import Link from '../link'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'

import * as styles from './item.module.css'

const BlogItem = ({ item = {}, lang }) => {
  const categories = () =>
    item.categories && item.categories.map((category, i) => (
      <span className={styles.category} key={i}>{category}</span>
    ))

  return (
    <Link className={styles.link} to={`${lang === 'eng' ? '/en' : ''}/post/${item.urlTitle || ''}`}>
      <div className={styles.blogItem}>
        <div className={styles.image}>
          {item.centerImage &&
            <img className='lazyload' data-src={imageUrlFor(buildImageObj(item.centerImage)).url()} />}
        </div>
        <div className={styles.categories}>{categories()}</div>
        <h3>{item.title || 'Article title'}</h3>
        <p>{item.subtitle || ''}</p>
        <span className={styles.time}>{item.date || 'Today'}</span>
      </div>
    </Link>
  )
}

export default BlogItem
